import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
//component
import CustomLink from "../components/Query/Link/CustomLink"
import Section from "../components/Section"
import SEO from "../components/SEO"
//css
import "../css/pageTemplate.css"
const PageTemplate = ({ pageContext, data }) => {
  const option = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <img
          src={node.data.target.fields.file["fr"].url}
          alt={node.data.target.fields.file["fr"].fileName}
          className="image"
        />
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={`${node.data.uri}`} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children, lang) => (
        <CustomLink
          to={`/${pageContext.node_locale}${
            node.data.target.fields.slug[pageContext.node_locale]
          }`}
        >
          {children}
        </CustomLink>
      ),
    },
  }
  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <Section classname="pageTemplate" container>
        {data.c.m ? (
          <div className="content rich">
            {documentToReactComponents(data.c.m.json, option)}
          </div>
        ) : (
          ""
        )}
      </Section>
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      metaDsc {
        metaDsc
      }
      m: childContentfulPageContentRichTextNode {
        json
      }
    }
  }
`

export default PageTemplate
